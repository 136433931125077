<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-blue opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Hola {{ user.name }}</h1>
            <p class="text-white mt-0 mb-5">
              Bienvenido a tu perfil de usuario. Aquí encontrarás la información
              relacionada a tu cuenta.
            </p>
            <a href="#my-account" class="btn btn-white">Editar perfil</a>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img
                      src="img/theme/team-4-800x800.jpg"
                      class="rounded-circle"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            >
              <div class="d-flex justify-content-between">
                <base-button size="sm" type="success" class="mr-4"
                  >En línea</base-button
                >
                <base-button size="sm" type="default" class="float-right"
                  >Probar Server</base-button
                >
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-center mt-md-5"
                  >
                    <div>
                      <span class="heading">22</span>
                      <span class="description">Agentes activos</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  {{ account.name }}
                </h3>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>{{ account.city }},
                  {{ account.country }}
                </div>
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i
                  >{{ account.description }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="my-account" class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Mi cuenta</h3>
                </div>
                <div class="col-4 text-right">
                  <a href="#!" class="btn btn-sm btn-primary">Configuración</a>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">
                  Información de usuario
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Usuario"
                        placeholder="Usuario"
                        input-classes="form-control-alternative"
                        v-model="user.name"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Correo Electrónico"
                        placeholder="ejemplo@ejemplo.com"
                        input-classes="form-control-alternative"
                        v-model="user.email"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Nombres"
                        placeholder="Nombres"
                        input-classes="form-control-alternative"
                        v-model="user.firstname"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Apellidos"
                        placeholder="Apellidos"
                        input-classes="form-control-alternative"
                        v-model="user.lastname"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">
                  Información del negocio
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-8">
                      <base-input
                        alternative=""
                        label="Nombre del negocio"
                        placeholder="Nombre del negocio"
                        input-classes="form-control-alternative"
                        v-model="account.name"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <base-input
                        alternative=""
                        label="RFC"
                        placeholder="RFC"
                        input-classes="form-control-alternative"
                        v-model="account.rfc"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <base-input
                        alternative=""
                        label="Dirección"
                        placeholder="Dirección"
                        input-classes="form-control-alternative"
                        v-model="account.address"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Ciudad"
                        placeholder="Ciudad"
                        input-classes="form-control-alternative"
                        v-model="account.city"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Estado"
                        placeholder="Estado"
                        input-classes="form-control-alternative"
                        v-model="account.state"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="País"
                        placeholder="País"
                        input-classes="form-control-alternative"
                        v-model="account.country"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Código postal"
                        placeholder="Código postal"
                        input-classes="form-control-alternative"
                        v-model="account.zipcode"
                      />
                    </div>
                    <div class="col-md-12">
                      <base-input
                        alternative=""
                        label="Descripción del negocio"
                      >
                        <textarea
                          v-model="account.description"
                          rows="4"
                          class="form-control form-control-alternative"
                          placeholder="Describe el negocio"
                        ></textarea>
                      </base-input>
                    </div>
                    <div class="col text-center">
                      <base-button v-on:click="postAccountInfo" type="primary"
                        >Guardar</base-button
                      >
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <base-alert
            ref="alert"
            :type="alert.type"
            :dismissible="true"
            :icon="alert.icon"
          >
            <template slot="text">{{ alert.message }}</template>
          </base-alert>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import httpMixin from "../mixins/httpMixin";
export default {
  name: "user-profile",
  data() {
    return {
      user: {
        id: 0,
        name: "",
        email: "",
        firstname: "",
        lastname: ""
      },
      account: {
        id: 0,
        name: "",
        address: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        description: "",
        schedule: "",
        pricingList: 0
      },
      alert: {
        type: "default",
        message: "",
        icon: ""
      }
    };
  },
  mounted() {
    this.initModel();
  },
  methods: {
    postAccountInfo: async function() {
      this.httpPut("/accounts", { user: this.user, account: this.account })
        .then(res => {
          this.alert.type = "success";
          this.alert.message = "Información guardada correctamente";
          this.alert.icon = "ni ni-like-2";
          this.$refs.alert.alertCreated();
        })
        .catch(err => {
          this.alert.type = "danger";
          this.alert.message = err.message;
          this.alert.icon = "ni ni-notification-70";
          this.$refs.alert.alertCreated();
        });
    },
    initModel: async function() {
      this.httpGet("/accounts")
        .then(res => {
          this.account = res.data;
        })
        .catch(err => {
          this.alert.type = "danger";
          this.alert.message = err.message;
          this.alert.icon = "ni ni-notification-70";
          this.$refs.alert.alertCreated();
        });
      this.httpGet("/user")
        .then(res => {
          this.user = res.data.user;
        })
        .catch(err => {
          this.alert.type = "danger";
          this.alert.message = err.message;
          this.alert.icon = "ni ni-notification-70";
          this.$refs.alert.alertCreated();
        });
    }
  },
  mixins: [httpMixin]
};
</script>
<style></style>
