<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}} | {{routes.length}}
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="primary" v-on:click="newRoute">Agregar</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive" style="min-height:250px;">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="routes">
        <template slot="columns">
          <th>Ruta</th>
          <th>Agente</th>
          <th>Estatus</th>
          <th>Acciones</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <a href="#" class="avatar rounded-circle mr-3">
                <img alt="Imagen usuario" src="img/theme/store-icon.jpg">
              </a>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.name}}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{row.agent}}
          </td>
          <td>
            <badge class="badge-dot mr-4" :type="convertStatusToColor(row.status)">
              <i :class="`bg-${convertStatusToColor(row.status)}`"></i>
              <span class="status">{{convertStatusToName(row.status)}}</span>
            </badge>
          </td>
          <td class="text-left">
            <base-dropdown class="dropdown"
                           position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <router-link class="dropdown-item" :to="'/ruta/' + row.id">Editar</router-link>
                <span v-if="row.status == 'A'" class="dropdown-item" v-on:click="updateStatus(row, 'I')" >Inactivar</span>
                <span v-if="row.status == 'I'" class="dropdown-item" v-on:click="updateStatus(row, 'A')" >Activar</span>
                <span class="dropdown-item" v-on:click="updateStatus(row, 'E')">Eliminar</span>
              </template>
            </base-dropdown>
          </td>

        </template>

      </base-table>
    </div>
  <!--
    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="30"></base-pagination>
    </div>
  -->
  </div>
</template>
<script>
import httpMixin from '../../mixins/httpMixin'
  export default {
    name: 'routes-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        routes: []
      }
    },
    mounted() {
      this.fetchRoutesInfo()
    },
    methods: {
      fetchRoutesInfo: function () {
        this.httpGet('/routes')
          .then(res => {
            this.routes = res.data;
          })
          .catch(err => {
            console.log(err)
          })
      },
      convertStatusToColor (status) {
        if (status == 'A') {
          return 'success'
        } else if (status == 'I') {
          return 'danger'
        }
      },
      convertStatusToName (status) {
        if (status == 'A') {
          return 'Activa'
        } else if (status == 'I') {
          return 'Inactiva'
        }
      },
      newRoute () {
        this.$router.push({ path: '/ruta/nuevo' })
      },
      updateStatus: async function (route, status) {
        route.status = status
        this.httpPut("/routes", {route: route})
          .then((res) => {
            this.fetchRoutesInfo()
          })
          .catch((err) => {
            console.log(err)
          });
      }
    },
    mixins: [httpMixin]
  }
</script>
<style>
</style>
